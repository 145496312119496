import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '@/views/HomePage';
import DetailPage from '@/views/DetailPage';

Vue.use(Router);


export const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/product/:id',
        name: 'Detail',
        component: DetailPage,
    },
    {
        path: '/brand/:brand',
        name: 'Brand',
        component: HomePage,
    },
    {
        path: '/search/:enName',
        name: 'Search',
        component: HomePage,
    }
]



export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
})

