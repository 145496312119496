<template>
  <div class="home-page-container">
    <AppHeader :brands="brands"></AppHeader>
    <div class="main-container">
      <el-row>
        <!-- 主图-->
        <el-col>
          <el-row>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="3">
              <div class="image-container main-img">
                <el-image :src="getThumbImageUrl(product.image)" fit="cover" class="image"></el-image>
              </div>
            </el-col>
            <el-col :xs="16" :sm="18" :md="20" :lg="21" :xl="21">
              <div class="main-description">
                <p class="main-attribute">{{ product.enName }}</p>
                <!--                <p class="other-attribute">其他属性: 描述内容</p>-->
                <!--                <p class="other-attribute">其他属性: 描述内容</p>-->
                <!--                <p class="other-attribute">其他属性: 描述内容</p>-->
              </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </el-col>

        <el-col>
          <div class="images-container">
            <!-- 相册列表 -->
            <el-row :gutter="15">
              <el-col :xs="8" :sm="6" :md="4" :lg="4" :xl="4" v-for="(imageSrc, index) in imageList" :key="index">
                <el-card class="box-card" :body-style="{ padding: '0px' }" shadow="hover">
                  <div class="image-container">
                    <el-image class="image" :src="imageSrc+'_tn'" fit="cover" @click="showImg(index)">
                      <div slot="placeholder" class="image-slot">
                        <el-image :src="loading"></el-image>
                      </div>
                    </el-image>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <SiteFooter></SiteFooter>
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue'; // 更新导入路径
import SiteFooter from './SiteFooter.vue'; // 更新导入路径
import loading from '@/assets/images/loading.gif';

import {getProduct, listBrand} from '@/api/product';


export default {
  name: 'DetailPage',
  components: {
    AppHeader,
    SiteFooter,
  },
  data() {
    return {
      loading: loading,
      brands: [],
      queryParams: {
        pageNum: 1,
        pageSize: 60,
        brandId: null,
        enName: null,
      },
      product: {
        enName: '',
        image: '',
      },
      imageList: [], // 存储图片 URL 的数组
      baseURL: 'https://s-cf-tw.shopeesz.com/file/'
    };
  },
  created() {
    this.getBrandList();
    this.getProductDetail(this.$route.params.id);
  },
  methods: {
    getThumbImageUrl(imageId) {
      if (imageId) {
        if (imageId.startsWith('http')) {
          return imageId;
        } else {
          return this.baseURL + imageId + "_tn";
        }
      }
      return '';
    },
    getBrandList() {
      listBrand().then(response => {
        this.brands = response;
      });
    },
    getProductDetail(id) {
      getProduct(id).then(response => {
        if (response.data) {
          this.product = response.data;
          const mainImagesArray = this.product.mainImages ? JSON.parse(this.product.mainImages) : [];
          const modelImagesArray = this.product.modelImages ? JSON.parse(this.product.modelImages) : [];
          this.imageList = [
            ...mainImagesArray.map(image => this.baseURL + image),
            ...modelImagesArray.map(image => this.baseURL + image)
          ];
        }
      });
    },
    showImg(index) {
      this.$hevueImgPreview({
        multiple: true,
        nowImgIndex: index,
        imgList: this.imageList
      })
    }
  },
}
</script>

<style scoped>
.main-container {
  justify-content: center;
  align-items: flex-start;
  padding: 30px 15px;
  max-width: 1350px;
  margin: 0 auto;
}

.box-card {
  margin-bottom: 15px;
  width: 100%; /* 使卡片宽度与列宽度一致 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 添加过渡效果 */
  transform: scale(1); /* 默认缩放比例 */
  overflow: hidden; /* 确保描述文本不会超出卡片边界 */
  cursor: pointer; /* 改变鼠标光标以指示可点击 */
}

.box-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* 增加阴影大小和透明度 */
  transform: scale(1.05); /* 轻微放大 */
}

/* 图片样式 */
.main-img {
  border: 1px solid #6e6969; /* 淡边框色 */
}

.image-container {
  position: relative; /* 为了绝对定位图片 */
  width: 100%; /* 容器宽度填满其父容器 */
  height: 0; /* 高度设置为0 */
  padding-bottom: 100%; /* padding-bottom 等于宽度的百分比，形成正方形 */
  overflow: hidden; /* 隐藏溢出的部分 */
}

.image {
  position: absolute; /* 绝对定位，相对于.image-container */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-description {
  padding-left: 30px;
}

.main-attribute {
  margin: 12px;
  font-weight: bold; /* 加粗字体以区分主要属性 */
  color: #333; /* 主要属性的颜色 */
}

.other-attribute {
  margin: 8px 18px;
  font-size: 0.9em; /* 略小的字体大小 */
  color: #666; /* 其他属性的颜色 */
}


</style>
