import axios from 'axios';


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,// 设置你的API基础URL
    timeout: 10000,
    headers: {'X-Custom-Header': 'foobar'}
});

// 请求拦截器
instance.interceptors.request.use(config => {
        if (localStorage.getItem('token')) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        // 是否需要防止数据重复提交
        const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }


        return config;
    },
    error => {
        console.log(error)
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(response => {
        // 对响应数据做点什么
        return response.data;
    },
    error => {
        // 对响应错误做点什么
        if (error.response && error.response.status === 401) {
            // 比如当返回401未授权错误时，你可以进行重定向到登录页面等操作
            console.error('未授权，请登录');
        }
        return Promise.reject(error);
    }
);

function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && value !== "" && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}

export default instance;
