<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'Novicta Watches Album',
      description: 'Discover custom elegance with our luxury watches, tailored just for you.',
    }
  }
}
</script>

<style>
body{
  margin: 0px;
}
</style>
