import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import '@/assets/styles/mytheme/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import locale from 'element-ui/lib/locale/lang/en'
// 分页组件
import Pagination from "@/components/Pagination";
// 头部标签组件
import VueMeta from 'vue-meta'
import hevueImgPreview from 'hevue-img-preview'

Vue.component('Pagination', Pagination)

Vue.use(ElementUI, {locale});
Vue.use(VueMeta)
Vue.use(hevueImgPreview)


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
