import request from '@/utils/api'

export function listBrand() {
    return request({
        url: '/product/brand/list',
        method: 'get',
    })
}

export function getProductsByBrand(query, brand) {
    return request({
        url: '/product/list/' + brand,
        method: 'get',
        params: query
    })
}

export function getProduct(productId) {
    return request({
        url: '/product/' + productId,
        method: 'get'
    })
}

