<template>
  <header class="app-header">
    <el-button class="icon-button hidden-md-and-up" icon="el-icon-notebook-2" circle @click="toggleList"></el-button>
    <div class="logo-container" @click="goHome">
      <img :src="logo" alt="Logo" class="logo">
    </div>
    <div class="search-container">
      <el-input placeholder="Search" v-model="searchQuery" class="search-input" @keyup.native.enter="handleSearch" size="small">
        <template #append>
          <el-button icon="el-icon-search" @click="handleSearch"></el-button>
        </template>
      </el-input>
    </div>
    <!-- 弹出的列表div -->
    <div class="list-container" v-show="isListVisible" @click.self="toggleList">
      <!-- 使用动画过渡 -->
      <div class="list-content">
        <!-- 两列列表 -->
        <el-menu class="el-menu-hide" @select="handleChose">
          <el-row>
            <el-col :span="12" v-for="brand in brands">
              <!-- 列表项增加底部虚线边框 -->
              <div class="list-item">
                <el-menu-item :index="brand.uri">{{ brand.enName }}</el-menu-item>
              </div>
            </el-col>
          </el-row>
        </el-menu>
      </div>
    </div>
  </header>
</template>

<script>
import logoImg from '@/assets/images/logo.png';

export default {
  name: 'AppHeader',
  props: ['brands'],
  data() {
    return {
      searchQuery: '',
      logo: logoImg,
      isListVisible: false, // 控制列表显示的数据属性
    };
  },
  methods: {
    handleSearch() {
      if (this.searchQuery === '') {
        return;
      }
      // 构建目标路径
      const path = '/search/' + this.searchQuery;
      // 检查即将导航的路径是否与当前路径相同
      if (this.$route.path !== path) {
        this.$router.push(path);
        //如果菜单打开，就关闭菜单
        this.isListVisible = false;
      }
    },
    toggleList() {
      // 切换列表的显示和隐藏
      this.isListVisible = !this.isListVisible;
    },
    handleChose(key) {
      const currentRoute = this.$route.path;
      const targetRoute = `/brand/${key}`;
      // 检查是否尝试进行重复导航
      if (currentRoute !== targetRoute) {
        // 使用编程式导航来改变路由
        this.$router.push(targetRoute);
      }
      this.isListVisible = !this.isListVisible;
    },
    goHome(){
      // 检查即将导航的路径是否与当前路径相同
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    }
  },
};
</script>

<style scoped>
.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 20px;
  background-color: #49bc85;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container img.logo {
  height: 35px;
  margin: 8px 12px 2px 12px;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%; /* 初始宽度为100%，适用于小屏幕 */
  opacity: 0.8; /* 设置搜索框的透明度为0.8 */
}

/* 媒体查询，用于屏幕尺寸大于sm时 */
@media (min-width: 768px) {
  .search-input {
    width: 300px; /* 在屏幕尺寸大于sm时，设置搜索框的宽度 */
  }
}

.icon-button {
  background-color: white; /* 图标按钮的背景色为白色 */
  margin-right: 0px; /* 与Logo的间距 */
  margin-left: 5px;
  opacity: 0.8; /* 设置搜索框的透明度为0.8 */
}

.list-container {
  position: absolute; /* 修改为绝对定位 */
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  z-index: 1000; /* 确保在顶层 */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.list-content {
  width: 100%; /* 列表内容的宽度 */
  max-width: 800px; /* 列表内容的最大宽度 */
  background-color: #fff; /* 列表内容的背景色 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 列表内容的阴影 */
  padding: 20px; /* 列表内容的内边距 */
  display: flex;
  flex-wrap: wrap; /* 列表内容的换行 */
  opacity: 0.9; /* 设置搜索框的透明度为0.8 */
  height: auto; /* 允许内容根据需要扩展高度 */
}

.list-item {
  border-bottom: 1px dashed #dcdfe6; /* 列表项的虚线边框 */
  padding: 0;
  width: 100%; /* 列表项的宽度 */
  box-sizing: border-box; /* 确保宽度包含padding和border */
}

.el-menu {
  border-right: none;
}

</style>
